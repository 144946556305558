import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  Redirect,
} from "react-router-dom";
import TabBar from "./common/TabBar";
import HeaderBar from "./common/HeaderBar";
import Table from "../components/Table";
import Commission from "../components/Commission";
import Edit from "../components/Edit";
import CommissionEdit from "../components/CommissionEdit";
import "../styles/entrance.scss";
import Home from "./Home";

class Entrance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reduce: false,
    };
    this.TabReduce = this.TabReduce.bind(this);
    this.receiveTabBarReduceState = this.receiveTabBarReduceState.bind(this);
  }

  reduceRef = React.createRef();

  TabReduce() {
    this.TabBar.clickChangeReduce();
  }

  componentDidMount() {
    console.log(this.reduceRef && this.reduceRef.current)
  }

  receiveTabBarReduceState(reduceState) {
    console.log(reduceState);
    this.setState({reduce: reduceState});
  }

  render() {
    const {reduce} = this.state;
    return (
      <div className="entrance-container">
        <div className={`menu-container ${reduce ? 'tab-reduce' : ''}`}>
          <TabBar
            {...this.props}
            receiveTabBarReduceState={this.receiveTabBarReduceState}
            ref={this.reduceRef}
          />
        </div>
        <div className={`page-route-container ${reduce ? 'page-enlarge' : ''}`}>
          <Switch>
            <Route path="/CommissionEdit" component={CommissionEdit}></Route>
            <Route path="/Edit" component={Edit}></Route>
            <Route path="/Commission" component={Commission}></Route>
            <Route path="/Table" component={Table}></Route>
            <Route path="/Home" component={Home}></Route>
            <Redirect to="/Home" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Entrance;
