import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../styles/commission.scss";
import XiaZai from "../icons/XiaZai";

import AddMessage from "../icons/AddMessage";

import Pagination from "@material-ui/lab/Pagination";
import * as GetDivided from "../apis/GetDividedAPI";
import cookie from "react-cookies";
import { setAPIAuthorizationToken } from "../apis/API";
import Loading from "../components/common/Loading";

class Commission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      table: [],
      count: 0,
      start: 0,
      length: 8,
      page: 1,
      stat: "",
      search: "",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.clickRouteCommissionEdit = this.clickRouteCommissionEdit.bind(this);
    this.searchData = this.searchData.bind(this);
    this.receiveSearchText = this.receiveSearchText.bind(this);
  }

  componentDidMount() {
    // 取cookie
    const param = cookie.load("token");
    if (param) {
      this.setState({ userID: param.group_id });
      setAPIAuthorizationToken(param.token);

      // table
      const { start, length, stat, search } = this.state;
      this.getDivided(start, length, stat, search);
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
  }

  // 获取table
  getDivided(start, length, stat, search) {
    this.setState({ loadingShow: true });
    GetDivided.GetDivided(start, length, stat, search).then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var status = res.status;
      if (status === 200) {
        var total = res.data.recordsTotal;
        total = Math.ceil(total / 8);
        console.log(total);
        this.setState({ count: total });
        var table = res.data.data;
        if (table) {
          console.log(table);
          this.setState({ table: table });
        }
      }
    });
  }

  handlePageChange(e, page) {
    this.setState({ page: page }, () => {
      let { start } = this.state;
      start = (page - 1) * 8;
      this.setState({ start: start }, () => {
        const { start, length, stat, search } = this.state;
        this.getDivided(0, length, stat, search);
      });
    });
  }

  searchRef = React.createRef();
  // 搜索
  searchData(searchText) {
    this.setState({ search: searchText }, () => {
      const { start, length, stat, search } = this.state;
      this.getDivided(start, length, stat, search);
    });
  }

  receiveSearchText(searchText) {
    this.setState({ search: searchText });
  }

  clickRouteCommissionEdit() {
    const { history } = this.props;
    history.push("/CommissionEdit");
  }

  render() {
    const { count, page, table, search } = this.state;
    const { ref } = this.props;
    return (
      <React.Fragment>
        <Loading loadingShow={this.state.loadingShow} />

        <HeaderBar
          {...this.props}
          ref={this.searchRef}
          searchCallback={this.searchData}
          updateParentSearchText={this.receiveSearchText}
        />
        <div className="content-body">
          <div className="commission-contain">
            <div className="commission-header">
              {/* <div className="download">
                <XiaZai size={35} color="#575757" />
              </div> */}
              <div className="add-btn" onClick={this.clickRouteCommissionEdit}>
                <AddMessage size={30} color="#575757" className="edit-icon" />
              </div>
            </div>
            <div className="form-body">
              <div className="form-header">
                <div className="time">Time</div>
                <div className="dollar">Dollar</div>
                <div className="euro">Euro</div>
                <div className="pound">Pound</div>
                <div className="auer">AUER</div>
              </div>
              <div className="form-contain">
                {table.map((item, index) => {
                  return (
                    <ul className="list">
                      <li className="time">{item.createtime}</li>
                      <li className="dollar">
                        <span></span>
                        {item.dollar}
                      </li>
                      <li className="euro">
                        <span></span>
                        {item.eur}
                      </li>
                      <li className="pound">
                        <span></span>
                        {item.gbp}
                      </li>
                      <li className="auer">
                        <span></span>
                        {item.aud}
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>
            <div className="pagination">
              <Pagination
                count={count}
                page={page}
                onChange={this.handlePageChange}
                variant="outlined"
                color="standard"
              />
            </div>
            <FooterBar {...this.props} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Commission;
