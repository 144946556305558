import React, { Component } from "react";
import "../styles/commissionEdit.scss";
import HeaderBar from "./common/HeaderBar";
import FootBar from "./common/FooterBar";
import CloseIcon from "../icons/CloseIcon";
import RemoveIcon from "../icons/RemoveIcon";

import * as UploadAPI from "../apis/UploadAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import { setAPIAuthorizationToken } from "../apis/API";
import * as AddDivided from "../apis/AddDividedAPI";
import cookie from "react-cookies";

import * as GetIndexAPI from "../apis/GetIndexAPI";
import Loading from "../components/common/Loading";

class CommissionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: [],
      img: [],
      dollar: [],
      eur: [],
      aud: [],
      gbp: [],
      address: "",
    };
    this.clickCreateRow = this.clickCreateRow.bind(this);
    this.clickRemoveItem = this.clickRemoveItem.bind(this);
    this.upload = this.upload.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.clickRemoveImg = this.clickRemoveImg.bind(this);
    this.changeInputAccount = this.changeInputAccount.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const param = cookie.load("token");
    if (param) {
      setAPIAuthorizationToken(param.token);
      this.setState({ loadingShow: true });
      GetIndexAPI.getIndex().then((res) => {
        this.setState({ loadingShow: false });
        console.log(res);
        var data = res.data.data;
        var code = res.data.code;
        if (code === 1) {
          if (data) {
            this.setState({ address: data.address });
          }
        }
      });
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
  }

  clickCreateRow(type) {
    const { row } = this.state;
    const obj = {
      account: "",
      commission: 0,
      total: 0,
      txt: `${type}`,
    };
    row.push(obj);
    this.setState({ row: row });
  }

  clickRemoveItem(index) {
    const { row } = this.state;
    row.splice(index, 1);
    this.setState({ row: row });
  }

  submit() {
    let { dollar, eur, gbp, aud, img } = this.state;
    if (img.length <= 0) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "please fill in at least one piece of information !",
        })
      );
      return;
    }
    if (
      dollar.length <= 0 &&
      eur.length <= 0 &&
      gbp.length <= 0 &&
      aud.length <= 0
    ) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "please fill in at least one piece of information !",
        })
      );
    }
    let params = {
      image: img.join(","),
      dollardata: [dollar.account, dollar.commission, dollar.total],
      eurdata: [eur.account, eur.commission, eur.total],
      gbpdata: [gbp.account, gbp.commission, gbp.total],
      auddata: [aud.account, aud.commission, aud.total],
      count: `￡${gbp.total || 0}+€${eur.total || 0}+$${dollar.total || 0}+AU$${
        aud.total || 0
      }`,
    };
    this.setState({ loadingShow: true });
    AddDivided.AddDivided(params).then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var msg = res.data.msg;
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
        const { history } = this.props;
        history.push("/Commission");
      }
    });
  }

  clickRemoveImg(index) {
    const { img } = this.state;
    img.splice(index, 1);
    this.setState({ img });
  }

  upload(e) {
    const { img } = this.state;
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.setState({ loadingShow: true });
    UploadAPI.upload(formData).then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var data = res.data.data;
        if (data) {
          img.push(data.url);
          this.setState({ img });
        }
      }
    });
  }

  changeInputAccount(e, txt) {
    const { row } = this.state;
    let account = e.target.value;
    row.map((item, j) => {
      if (txt === item.txt) {
        item.account = account;
        switch (txt) {
          case "$":
            this.setState({ dollar: item });
            break;
          case "€":
            this.setState({ eur: item });
            break;
          case "￡":
            this.setState({ gbp: item });
            break;
          case "AU$":
            this.setState({ aud: item });
            break;
          default:
            break;
        }
      }
      this.setState({ row });
    });
  }

  changeInput(e, txt) {
    console.log(e.target.value);
    let num = e.target.value;
    let count = (num * 0.05).toFixed(2);
    const { row } = this.state;
    row.map((item, j) => {
      if (txt === item.txt) {
        item.commission = num;
        item.total = count;
        switch (txt) {
          case "$":
            this.setState({ dollar: item });
            break;
          case "€":
            this.setState({ eur: item });
            break;
          case "￡":
            this.setState({ gbp: item });
            break;
          case "AU$":
            this.setState({ aud: item });
            break;
          default:
            break;
        }
      }
      this.setState({ row });
    });
  }

  render() {
    const { row, img, eur, dollar, gbp, aud, address } = this.state;
    console.log(row);
    console.log(eur);
    console.log(dollar);
    return (
      <React.Fragment>
        <Loading loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        <div className="content-body">
          <div className="commissionEdit-contain">
            <h1>Modify</h1>

            <div className="header">
              <h3>Block Level Buttons</h3>
              <div className="row">
                <div
                  className="pound"
                  onClick={() => this.clickCreateRow("￡")}
                >
                  Create Pound Commission ￡
                </div>
                <div className="euro" onClick={() => this.clickCreateRow("€")}>
                  Create Euro Commission €
                </div>
                <div
                  className="dollar"
                  onClick={() => this.clickCreateRow("$")}
                >
                  Create Dollar Commission $
                </div>
                <div
                  className="auer"
                  onClick={() => this.clickCreateRow("AU$")}
                >
                  Create AUER Commission AU$
                </div>
              </div>
            </div>

            {row.map((item, index) => {
              return (
                <div className="row-body" key={index || ""}>
                  <div
                    className="remove"
                    onClick={() => this.clickRemoveItem(index)}
                  >
                    <CloseIcon
                      size={20}
                      color="#969ba0"
                      className="close-icon"
                    />
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Account</p>
                      <input
                        type="text"
                        onChange={(e) => this.changeInputAccount(e, item.txt)}
                      />
                    </div>
                    <div className="col">
                      <p>10 Days commission total</p>
                      <div className="money-box">
                        <input
                          type="number"
                          onChange={(e) => this.changeInput(e, item.txt)}
                        />
                        <span>0.05</span>
                      </div>
                    </div>
                    <div className="col">
                      <p>Total amount</p>
                      <div className="money-box">
                        {item.txt === "$" && (
                          <input type="text" value={item.total || 0} />
                        )}
                        {item.txt === "€" && (
                          <input type="text" value={item.total || 0} />
                        )}
                        {item.txt === "￡" && (
                          <input type="text" value={item.total || 0} />
                        )}
                        {item.txt === "AU$" && (
                          <input type="text" value={item.total || 0} />
                        )}
                        <span>{item.txt}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* 打款证明 */}
            <div className="prove">
              <div className="row">
                <div className="title">Proof of payment</div>
                <div className="file-box">
                  <div className="file">
                    <p>Choose File</p>
                    <div className="btn">Browse</div>
                    <input
                      type="file"
                      className="file-input"
                      onChange={(e) => this.upload(e)}
                    />
                  </div>
                  <div className="img-row">
                    {img.map((item, index) => {
                      return (
                        <div className="img-box" key={index || ""}>
                          <div
                            className="remove"
                            onClick={() => this.clickRemoveImg(index)}
                          >
                            <RemoveIcon
                              size={20}
                              color="#000"
                              className="close-icon"
                            />
                          </div>
                          <img src={BASE_URL + item} alt="" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* submit */}
            <div className="commission-footer">
              <div className="row">
                <div className="col">
                  ￡ {gbp.total || 0} + € {eur.total || 0} + ${" "}
                  {dollar.total || 0} + AU$ {aud.total || 0}
                </div>
                <div className="col">
                  <div className="submit" onClick={this.submit}>
                    submit
                  </div>
                </div>
                <div className="col">
                  <p>
                    Collection Address :<span>{address}</span>
                  </p>
                  <p>USDT-TRC20 Tail Number Dcb9 please confirm</p>
                </div>
              </div>
            </div>
            <FootBar />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CommissionEdit;
