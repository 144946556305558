import React, { Component } from "react";
import "../../styles/common/headerBar.scss";
import SearchIcon from "../../icons/SearchIcon";
import NotifyIcon from "../../icons/NotifyIcon";
import MessageIcon from "../../icons/MessageIcon";
import GiftIcon from "../../icons/GiftIcon";
import DataIcon from "../../icons/DataIcon";

import HamburgerIcon from "../../icons/HamburgerIcon";
import userImg from "../../image/pic1.jpg";
import LogOutIcon from "../../icons/LogOutIcon";
import cookie from "react-cookies";

import { setAPIAuthorizationToken } from "../../apis/API";
import * as LoginAPI from "../../apis/LoginAPI";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      userID: "",
      username: "",
      avatar: "",
    };
    this.inputSearch = this.inputSearch.bind(this);
    this.searchNow = this.searchNow.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    const param = cookie.load("token");
    if (param) {
      setAPIAuthorizationToken(param.token);
      this.setState({ username: param.username });
      this.setState({ avatar: param.avatar });
      this.setState({ userID: param.group_id });
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
    let { location } = this.props;
    if (location.pathname === "/Home") {
      this.setState({ title: "Dashboard" });
    } else if (location.pathname === "/Table") {
      this.setState({ title: "Table" });
    } else if (location.pathname === "/Commission") {
      this.setState({ title: "Commission" });
    } else if (
      location.pathname === "/Edit" ||
      location.pathname === "/CommissionEdit"
    ) {
      this.setState({ title: "Edit" });
    }
  }
  // logout
  logOut() {
    const param = cookie.load("token");
    if (param) {
      setAPIAuthorizationToken(param.token);
    }
    LoginAPI.LogOut().then((res) => {
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var msg = res.data.msg;
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
        cookie.remove("token");
        const { history } = this.props;
        history.push("/Login");
      }
    });
  }

  searchNow() {
    const { searchCallback } = this.props;
    searchCallback && searchCallback(this.state.search);
  }

  inputSearch(e) {
    this.setState({ search: e.target.value });
    const { updateParentSearchText } = this.props;
    updateParentSearchText && updateParentSearchText(e.target.value);
  }

  render() {
    const { title, username, avatar, userID } = this.state;
    let {location} = this.props;
    return (
      <React.Fragment>
        <header>
          <div className="head-bar">
            <div className="nav-header">
              <div className="header-left">
                
                <h1>{title}</h1>
              </div>
              <div className="header-right">
                {/* search */}
                {location.pathname !== '/Home' && <div className="search-box">
                  <input
                    type="text"
                    placeholder="Search here ... "
                    className="search"
                    onChange={(e) => this.inputSearch(e)}
                  />
                  <SearchIcon
                    size={30}
                    onClick={this.searchNow}
                    className="search-icon"
                  />
                </div>}
                <div className="profile">
                  <div className="left">
                    <div className="img-box">
                      <img src={avatar} alt="" />
                    </div>
                  </div>
                  {/* logout */}
                  <div className="logout" onClick={this.logOut}>
                    <LogOutIcon
                      size={36}
                      color="#5bcfc5"
                      className="logout-icon"
                    />
                  </div>
                </div>
                {/* <ul className="action-bar">
                  <li>
                    <a>
                      <GiftIcon size={34} color="#4f7086" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <NotifyIcon size={34} color="#4f7086" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <MessageIcon size={34} color="#4f7086" />
                    </a>
                  </li>
                </ul> */}
                {/* <div className="generate">
                  Generate Report <DataIcon size={20} color="#fff" />
                </div> */}
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
