import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const recharge = (txt) => {
  return api.post(
    `${ApiEndpointConstants.RECHARGE}`,
    { txid: txt },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
