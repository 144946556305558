import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const AddDivided = (params) => {
  return api.post(
    `${ApiEndpointConstants.ADD_DIVIDED}`,
    params ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
