import React from "react";

const DownIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#9fa4a6",
  ...props
}) => (
  <svg
    viewBox="0 0 1741 1024"
    p-id="76223"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M-63.882631 154.789814m109.000891-109.000891l0 0q109.00089-109.00089 218.001781 0l716.291566 716.291566q109.00089 109.00089 0 218.001781l0 0q-109.00089 109.00089-218.001781 0l-716.291566-716.291566q-109.00089-109.00089 0-218.001781Z"
      p-id="76224"
      fill={color}
    ></path>
    <path
      d="M1584.538466-64.37302m109.000891 109.000891l0 0q109.00089 109.00089 0 218.001781l-716.291566 716.291566q-109.00089 109.00089-218.001781 0l0 0q-109.00089-109.00089 0-218.001781l716.291566-716.291566q109.00089-109.00089 218.001781 0Z"
      p-id="76225"
      fill={color}
    ></path>
  </svg>
);
export default DownIcon;
