
class LocalStorageUtil {
    static alertModalKey = 'pop';
    static alertModalInterval = 3000;

    static displayAlertModal() {
        // if (!value) return;
        // if (value['state'] === undefined) {
        //     value['state'] = true;
        // }
        localStorage.setItem(this.alertModalKey, 1);
        setTimeout(() => {
            this.closeAlertModal();
        }, this.alertModalInterval);
    }

    static closeAlertModal() {
        // let pop = localStorage.getItem(this.alertModalKey);
        // if (pop) {
        //     pop = JSON.parse(pop);
        //     pop.state = false;
        // }
        localStorage.setItem(this.alertModalKey, 0);
    }
    
    static getAlertModal() {
        let alertModal = localStorage.getItem(this.alertModalKey);
        return alertModal && JSON.parse(alertModal);
    }

}

export default LocalStorageUtil;