import React, { Component } from "react";
import "../../styles/common/popup.scss";
import LocalStorageUtil from "../../utils/LocalstorageUtil";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popState: false,
      popMsg: false,
    };
  }
  
  alertModalTimeoutInterval = null;

  componentDidMount() {
    window.addEventListener('showAlert', (event) => {
        this.setState({
            popState: true,
            popMsg: event &&event.detail
        });
        if (this.alertModalTimeoutInterval) {
            window.clearTimeout(this.alertModalTimeoutInterval);
        }
        this.alertModalTimeoutInterval = window.setTimeout(() => {
            this.setState({popState: false, popMsg: ''});
        }, 3000)
        console.log(event);
    })
  }

  componentWillUnmount() {
    console.error('unmount-----------')
    if (this.alertModalTimeoutInterval) {
        window.clearTimeout(this.alertModalTimeoutInterval);
    }
  }

  render() {
    const { popMsg, popState } = this.state;
    return (
      <div
        className={`pop-box ${
            popState ? "pop-show" : ""
        } `}
      >
        <h4>{popMsg}</h4>
      </div>
    );
  }
}

export default Popup;
