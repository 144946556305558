import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const submit = (param) => {
  return api.post(`${ApiEndpointConstants.ADD}`, param, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
