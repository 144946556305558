import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const GetDivided = (start, length, stat, search) => {
return api.post(`${ApiEndpointConstants.GET_DIVIDED}`,{
    start,
    length,
    stat,
    "search[value]": search,
  },
  {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}