import React from "react";

const CloseIcon = ({
    viewBox= '0 0 24 24',
    size = 24,
    color = '#423939',
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="29346"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M554.44316 555.388183c-0.648776 0-1.178849 0.551562-1.178849 1.233084 0 0.331551 0.125867 0.64366 0.354064 0.87902 0.186242 0.352017 0.201591 0.523933 0.201591 0.551562-0.072655 0.308015 0.138146 0.466628 0.161682 0.484024 0.00614 0.00614 0.143263 0.102331 0.407276 0.102331 0.155543 0 0.324388-0.034792 0.499373-0.096191l0.008186-0.00307 0.00307-0.00307c0.002047-0.001023 0.240477-0.160659 0.162706-0.484024 0-0.027629 0.01842-0.193405 0.192382-0.535189 0.234337-0.232291 0.366344-0.557702 0.366344-0.895393C555.623032 555.939745 555.095006 555.388183 554.44316 555.388183zM554.431903 558.835701c-0.110517 0-0.216941-0.01842-0.310062-0.050142l-0.035816-0.01228 0.027629 0.413416 0.200568 0.169869 0.23536 0 0.208754-0.176009 0.047072-0.407276-0.040932 0.01228C554.765501 558.785559 554.621215 558.835701 554.431903 558.835701zM552.105928 556.677549l0 0.326435 0.466628 0c-0.004093-0.054235-0.016373-0.107447-0.016373-0.162706 0-0.057305 0.01228-0.10847 0.016373-0.163729L552.105928 556.677549zM556.31581 556.678572c0.004093 0.054235 0.017396 0.106424 0.017396 0.162706 0 0.055259-0.013303 0.10847-0.017396 0.163729l0.468674 0 0-0.326435L556.31581 556.678572zM554.716382 554.979884l0-0.532119-0.327458 0 0 0.509606c0.020466 0 0.037862-0.00614 0.056282-0.00614C554.538327 554.952254 554.629401 554.965557 554.716382 554.979884zM552.620651 555.303248l0.360204 0.358157c0.069585-0.083911 0.145309-0.163729 0.226151-0.234337l-0.354064-0.355087L552.620651 555.303248zM555.653731 555.401486c0.083911 0.070608 0.159636 0.148379 0.232291 0.232291l0.329505-0.329505-0.233314-0.232291L555.653731 555.401486z"
      fill={color}
      p-id="29347"
    ></path>
    <path
      d="M714.048227 512.047072l201.923896 201.980177c55.791677 55.734372 55.791677 146.250922 0 201.980177-55.796794 55.735395-146.250922 55.735395-201.923896 0l-202.103997-201.980177-201.980177 201.980177c-55.732325 55.735395-146.127102 55.735395-201.980177 0-55.734372-55.729256-55.734372-146.245805 0-201.980177l201.980177-201.980177-201.980177-201.985294c-55.734372-55.851029-55.734372-146.247852 0-201.980177 55.854099-55.854099 146.247852-55.854099 201.980177 0l201.980177 201.980177 202.103997-201.980177c55.672974-55.854099 146.127102-55.854099 201.923896 0 55.791677 55.732325 55.791677 146.129148 0 201.980177L714.048227 512.047072 714.048227 512.047072z"
      fill={color}
      p-id="29348"
    ></path>
  </svg>
);

export default CloseIcon;
