import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const Login = (account, password, code) => {
  return api.post(`${ApiEndpointConstants.LOGIN}`, { account, password, code });
};

export const LogOut = () => {
  return api.post(`${ApiEndpointConstants.LOGOUT}`);
};
