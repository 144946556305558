import React from "react";

const BagIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#000",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="8690"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M673.5872 25.6c38.1952-15.5648 81.8176 2.7648 97.3824 41.0624l66.56 163.4304H176.2304L673.5872 25.6z"
      p-id="8691"
      fill={color}
    ></path>
    <path
      d="M889.5488 284.7744H127.8976C87.8592 284.7744 55.296 317.2352 55.296 357.376v573.44c0 19.2512 7.68 37.6832 21.2992 51.3024 13.6192 13.6192 32.0512 21.2992 51.3024 21.2992h761.6512c40.0384 0 72.6016-32.4608 72.6016-72.6016V357.376c0-40.1408-32.5632-72.6016-72.6016-72.6016z m-237.568 430.8992c-4.7104 4.8128-11.1616 7.4752-17.8176 7.4752h-98.9184v92.0576c0.3072 9.1136-4.4032 17.6128-12.1856 22.2208-3.7888 2.2528-8.192 3.4816-12.6976 3.4816s-8.8064-1.2288-12.6976-3.4816c-7.7824-4.608-12.4928-13.1072-12.1856-22.2208v-92.16h-102.1952c-0.7168 0-1.3312 0.1024-1.8432 0.1024-6.2464 0-12.288-2.56-16.5888-6.9632-4.8128-4.9152-7.168-11.6736-6.4512-18.5344-0.2048-6.8608 2.3552-13.4144 7.0656-18.2272 4.608-4.8128 11.1616-7.4752 17.8176-7.4752h102.1952v-47.8208h-102.1952c-0.7168 0-1.3312 0.1024-1.8432 0.1024-6.2464 0-12.288-2.56-16.5888-6.9632-4.8128-4.9152-7.168-11.6736-6.4512-18.5344-0.2048-6.8608 2.3552-13.4144 7.0656-18.2272 4.608-4.8128 11.1616-7.4752 17.8176-7.4752h67.9936l-80.0768-81.6128c-5.7344-4.7104-9.216-11.3664-9.8304-18.7392-0.6144-7.168 1.8432-14.4384 6.5536-19.968 4.8128-3.6864 10.6496-5.5296 16.7936-5.5296 7.7824 0 15.2576 3.2768 20.5824 9.0112L506.9824 560.128l105.0624-103.8336c4.1984-5.0176 10.0352-8.192 16.4864-8.8064 0.7168-0.1024 1.536-0.1024 2.2528-0.1024 5.632 0 11.1616 2.048 15.4624 5.632 5.12 4.4032 8.0896 10.752 8.0896 17.5104 0 6.7584-2.9696 13.1072-8.0896 17.5104l-83.5584 81.7152H630.784c0.7168 0 1.3312-0.1024 1.8432-0.1024 6.2464 0 12.288 2.56 16.5888 6.9632 4.8128 4.9152 7.168 11.6736 6.4512 18.5344 0.2048 6.8608-2.3552 13.4144-7.0656 18.2272-4.608 4.8128-11.1616 7.4752-17.8176 7.4752h-95.5392v51.2h98.9184c0.7168 0 1.3312-0.1024 1.8432-0.1024 6.2464 0 12.288 2.56 16.5888 6.9632 4.8128 4.9152 7.168 11.6736 6.4512 18.5344 0.1024 6.7584-2.3552 13.312-7.0656 18.2272z"
      p-id="8692"
      fill={color}
    ></path>
  </svg>
);

export default BagIcon;
