import React, { Component } from "react";
import "../styles/login.scss";
import * as GetCodeAPI from "../apis/GetCodeAPI";
import * as LoginAPI from "../apis/LoginAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import cookie from "react-cookies";
import { setAPIAuthorizationToken } from "../apis/API";
import Loading from "../components/common/Loading";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      account: "",
      password: "",
      code: "",
    };
    this.changeInputValue = this.changeInputValue.bind(this);
    this.GetCode = this.GetCode.bind(this);
    this.Login = this.Login.bind(this);
  }

  changeInputValue(type, e) {
    switch (type) {
      case "account":
        this.setState({
          account: e.target.value,
        });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      case "code":
        this.setState({ code: e.target.value });
        break;
      default:
        break;
    }
  }

  GetCode() {
    const { account } = this.state;
    this.setState({ loadingShow: true });
    GetCodeAPI.GetCode(account).then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var msg = res.data.msg;
      console.log(msg);
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: msg,
        })
      );
    });
  }

  Login() {
    const { account, password, code } = this.state;
    this.setState({ loadingShow: true });
    LoginAPI.Login(account, password, code).then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var code = res.data.code;
      if (code === 0) {
        var msg = res.data.msg;
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      }
      if (code === 1) {
        var data = res.data.data.userinfo;
        if (data) {
          console.log(data);
          var token = data.token;
          console.log(token);
          setAPIAuthorizationToken(token);
          let expires_in = data.expires_in;
          let time = new Date(new Date().getTime() + expires_in * 1000);
          const param = data;
          cookie.save("token", param, { expires: time, path: "/" });
          const { history } = this.props;
          history.push("/");
        }
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <Loading loadingShow={this.state.loadingShow} />
        <section>
          <div className="color"></div>
          <div className="color"></div>
          <div className="color"></div>

          <div className="box">
            <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div>
            <div className="square"></div>

            <div className="container">
              <div className="form">
                <h2>StripeVip System</h2>
                <p>Welcome back, Please Login to your Account.</p>
                <form>
                  <div className="input__box">
                    <input
                      type="text"
                      placeholder="Username"
                      onChange={(e) => this.changeInputValue("account", e)}
                    />
                  </div>

                  <div className="input__box">
                    <input
                      type="password"
                      placeholder="Password"
                      onChange={(e) => this.changeInputValue("password", e)}
                    />
                  </div>
                  <div className="input__box">
                    <input
                      type="text"
                      placeholder="Code"
                      onChange={(e) => this.changeInputValue("code", e)}
                    />
                  </div>

                  <div className="row">
                    <div className="code" onClick={this.GetCode}>
                      Get code
                    </div>
                    <div className="submit" onClick={this.Login}>
                      Login
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Login;
