import React from "react";

const AddMessage = ({
  viewBox = "0 0 24 24",
  color = "#8A8F9C",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="48327"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1024 514.688h-96V128a32 32 0 0 0-32-32H128a32 32 0 0 0-32 32v768a32 32 0 0 0 32 32h489.088V1024H128a128 128 0 0 1-128-128V128a128 128 0 0 1 128-128h768a128 128 0 0 1 128 128z"
      fill={color}
      p-id="48328"
    ></path>
    <path d="M576 848v-96h445.44v96z" fill={color} p-id="48329"></path>
    <path d="M752 576h96v447.168h-96z" fill={color} p-id="48330"></path>
    <path
      d="M288 256h448a28.288 28.288 0 0 1 32 32 28.288 28.288 0 0 1-32 32h-448a28.288 28.288 0 0 1-32-32 28.288 28.288 0 0 1 32-32z"
      fill={color}
      p-id="48331"
    ></path>
    <path
      d="M288 448h448a28.288 28.288 0 0 1 32 32 28.288 28.288 0 0 1-32 32h-448a28.288 28.288 0 0 1-32-32 28.288 28.288 0 0 1 32-32z"
      fill={color}
      p-id="48332"
    ></path>
    <path
      d="M288 640h192a28.288 28.288 0 0 1 32 32 28.288 28.288 0 0 1-32 32h-192a28.288 28.288 0 0 1-32-32 28.288 28.288 0 0 1 32-32z"
      fill={color}
      p-id="48333"
    ></path>
  </svg>
);

export default AddMessage;
