import React from "react";

const CopyIcon = ({
    viewBox= '0 0 24 24',
    size = 24,
    color = '#000',
    ...props

}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="32900"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M930.36 157v504.16a62.9 62.9 0 0 1-62.83 63h-83.17V350.19c0-60.7-49.28-110.07-109.85-110.07H300.36V157a62.88 62.88 0 0 1 62.93-62.83h504.15A62.92 62.92 0 0 1 930.36 157z"
      fill={color}
      p-id="32901"
    ></path>
    <path
      d="M674.36 300.12h-530a50 50 0 0 0-50 50v530a50 50 0 0 0 50 50h530a50 50 0 0 0 50-50v-530a50 50 0 0 0-50-50z m-132.48 435H276.52c-15.81 0-29.37-12-30.51-27.76a30 30 0 0 1 29.92-32.24h265.35c15.81 0 29.37 12 30.51 27.76a30 30 0 0 1-29.92 32.24z m0-180H276.52c-15.81 0-29.37-12-30.51-27.76a30 30 0 0 1 29.92-32.24h265.35c15.81 0 29.37 12 30.51 27.76a30 30 0 0 1-29.92 32.24z"
      fill={color}
      p-id="32902"
    ></path>
  </svg>
);

export default CopyIcon;