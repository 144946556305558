import React from "react";
const ManagementIcon = ({
  size = 24,
  color = "#9fa4a6",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="72137"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M388.4 578.6H139.5c-22.9 0-41.5-19.6-41.5-43.7v-393c0-24.1 18.6-43.7 41.5-43.7h248.9c22.9 0 41.5 19.6 41.5 43.7v393c0 24.2-18.6 43.7-41.5 43.7zM388.4 928H139.5c-22.9 0-41.5-19.6-41.5-43.7V709.6c0-24.1 18.6-43.7 41.5-43.7h248.9c22.9 0 41.5 19.6 41.5 43.7v174.7c0 24.1-18.6 43.7-41.5 43.7zM886.3 360.3H554.4c-22.9 0-41.5-19.6-41.5-43.7V141.9c0-24.1 18.6-43.7 41.5-43.7h331.9c22.9 0 41.5 19.6 41.5 43.7v174.7c0 24.1-18.6 43.7-41.5 43.7zM886.3 928H554.4c-22.9 0-41.5-19.6-41.5-43.7v-393c0-24.1 18.6-43.7 41.5-43.7h331.9c22.9 0 41.5 19.6 41.5 43.7v393c0 24.1-18.6 43.7-41.5 43.7z"
      fill={color}
      p-id="72138"
    ></path>
  </svg>
);

export default ManagementIcon;