import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const getMaterial = (id) => {
  return api.post(
    `${ApiEndpointConstants.MATERIAL}`,
    { id },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
