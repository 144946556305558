import React from "react";
const RightAllowIcon = ({
  size = 24,
  color = "#9fa4a6",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="33802"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M244.49473015 821.23097933l0-618.09332674a61.55540565 61.55540565 0 0 1 102.70909117-46.14466413L755.06310938 462.62477162a61.73052794 61.73052794 0 0 1 12.21476417 86.29139725 54.06893738 54.06893738 0 0 1-12.21476417 12.21476416L342.82576928 870.61540138a61.51162507 61.51162507 0 0 1-86.0724944-12.43366629 60.06686841 60.06686841 0 0 1-12.43366702-36.81941405z m0 0"
      p-id="33803"
      fill={color}
    ></path>
  </svg>
);

export default RightAllowIcon;
