import React from "react";
const DownAllowIcon = ({
    size = 24,
    color = "#9fa4a6",
    viewBox = "0 0 24 24",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="33937"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M953.26408249 284.58198914c0 0-385.25976779 501.72937609-385.25976779 501.72937607-26.77234599 34.87227197-84.82203117 34.87227197-111.59308261 1e-8 0 0-385.25976779-501.72937609-385.25976779-501.72937608-24.82277136-32.32203156 6.22414-72.74787271 55.87356633-72.74787271 0 0 770.44574689 0 770.44574688 0 49.57304857 0 80.62125449 40.42195753 55.79848313 72.74787271 0 0 0 0 0 0z"
      p-id="33938"
      fill={color}
    ></path>
  </svg>
);

export default DownAllowIcon;
