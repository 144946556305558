import React from "react";
const ChartIcon = ({
  size = 24,
  color = "#9fa4a6",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="74605"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M958.74048 355.712l-321.01888 232.50944c-7.19872 3.91168-14.68416 6.12864-19.96288 6.12864-5.57568 0-13.27616-2.8416-19.9168-6.12864L387.4048 436.34688l-284.51328 205.25568a31.46752 31.46752 0 0 1-18.72896 6.13376c-10.46016 0-20.64384-5.12512-27.0592-14.5408-10.34752-15.27296-6.74816-36.56704 8.15616-47.25248l302.17728-218.02496a31.5904 31.5904 0 0 1 18.73408-6.07744h2.47808c6.47168 0 12.99456 1.94048 18.73408 6.07744l210.3808 151.93088 303.35488-219.79648a31.79008 31.79008 0 0 1 18.72896-6.07744c10.46528 0 20.64384 5.03296 27.0592 14.48448 10.3424 15.32928 6.74304 36.56192-8.1664 47.25248z m-97.82272-192.65536H163.07712v279.1424l-58.1632 46.52032V104.89344H919.08608v116.32128l-58.1632 40.72448V163.05664zM163.07712 860.94848h697.84576V512l58.1632-46.52032V919.10656H104.91392v-209.3568l58.1632-34.93376v186.13248z"
      fill={color}
      p-id="74606"
    ></path>
  </svg>
);
export default ChartIcon;