import React, { Component } from "react";
import "../../styles/common/tabBar.scss";
import LogoIcon from "../../icons/LogoIcon";
import HamburgerIcon from "../../icons/HamburgerIcon";
import ManagementIcon from "../../icons/ManagementIcon";
import RightAllowIcon from "../../icons/RightAllowIcon";
import DownAllowIcon from "../../icons/DownAllowIcon";
import ChartIcon from "../../icons/ChartIcon";

import AllowRight from "../../icons/AllowRight";
import MoneyIcon from "../../icons/MoneyIcon";
import cookie from "react-cookies";

import { setAPIAuthorizationToken } from "../../apis/API";
import * as LoginAPI from "../../apis/LoginAPI";

class TabBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: "",
      username: "",
      avatar: "",
      reduce: false,
    };

    this.clickToHomePage = this.clickToHomePage.bind(this);
    this.clickToTablePage = this.clickToTablePage.bind(this);
    this.clickToCommissionPage = this.clickToCommissionPage.bind(this);
    this.toggleTabReducerState = this.toggleTabReducerState.bind(this);

    // this.props.onRef && this.props.onRef(this);
  }
  componentDidMount() {
    const param = cookie.load("token");
    if (param) {
      setAPIAuthorizationToken(param.token);
      this.setState({ username: param.username });
      this.setState({ avatar: param.avatar });
      this.setState({ userID: param.group_id });
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
  }
  // 跳转home
  clickToHomePage() {
    const { history } = this.props;
    history.push("/Home");
  }
  clickToTablePage() {
    const { history } = this.props;
    history.push("/Table");
  }
  clickToCommissionPage() {
    const { history } = this.props;
    history.push("/Commission");
  }

  toggleTabReducerState() {
    const { receiveTabBarReduceState } = this.props;
    this.setState({ reduce: !this.state.reduce }, () => {
      receiveTabBarReduceState && receiveTabBarReduceState(this.state.reduce);
    });
  }

  render() {
    let { location } = this.props;
    const { username, avatar, userID, reduce } = this.state;
    return (
      <React.Fragment>
        <div className={`tab-box`}>
          <div className="tab">
            <div className="nav-header">
              <a>
                <LogoIcon size={40} className="logo" />
                <span>Stripe vip</span>
              </a>

              <a>
                <HamburgerIcon
                  size={40}
                  onClick={this.toggleTabReducerState}
                  className="hamburger"
                />
              </a>
            </div>
            {reduce && (
              <div className="arrow-icon-container" onClick={this.toggleTabReducerState}>
                <AllowRight size={36} className="right" />
              </div>
            )}

            <ul className="tab-body">
              <div className="profile">
                <div className="left">
                  <div className="img-box">
                    <img src={avatar} alt="" />
                  </div>
                  <div className="user-text">
                    <p>
                      Hi,<b>{username}</b>
                    </p>
                  </div>
                </div>
              </div>
              <li onClick={this.clickToHomePage}>
                <a className={location.pathname === "/Home" ? "li-active" : ""}>
                  <div className="left">
                    {location.pathname === "/Home" ? (
                      <ManagementIcon size={30} color="#5bcfc5" className="icon"/>
                    ) : (
                      <ManagementIcon size={30} className="icon"/>
                    )}

                    <span
                      className={`txt ${
                        location.pathname === "/Home" ? "txt-active" : ""
                      } `}
                    >
                      Dashboard
                    </span>
                  </div>
                  {location.pathname === "/Home" ? (
                    <RightAllowIcon size={20} color="#5bcfc5" className="icon-right"/>
                  ) : (
                    <RightAllowIcon size={20} className="icon-right"/>
                  )}
                </a>
              </li>
              <li onClick={this.clickToTablePage}>
                <a
                  className={
                    location.pathname === "/Table" ||
                    location.pathname === "/Edit"
                      ? "li-active"
                      : ""
                  }
                >
                  <div className="left">
                    {location.pathname === "/Table" ||
                    location.pathname === "/Edit" ? (
                      <ChartIcon size={30} color="#5bcfc5" className="icon"/>
                    ) : (
                      <ChartIcon size={30} className="icon"/>
                    )}

                    <span
                      className={`txt ${
                        location.pathname === "/Table" ||
                        location.pathname === "/Edit"
                          ? "txt-active"
                          : ""
                      } `}
                    >
                      Table
                    </span>
                  </div>
                  {location.pathname === "/Table" ||
                  location.pathname === "/Edit" ? (
                    <RightAllowIcon size={20} color="#5bcfc5" className="icon-right"/>
                  ) : (
                    <RightAllowIcon size={20} className="icon-right"/>
                  )}
                </a>
              </li>
              {userID === 3 ? (
                <li onClick={this.clickToCommissionPage}>
                  <a
                    className={
                      location.pathname === "/Commission" ||
                      location.pathname === "/CommissionEdit"
                        ? "li-active"
                        : ""
                    }
                  >
                    <div className="left">
                      {location.pathname === "/Commission" ||
                      location.pathname === "/CommissionEdit" ? (
                        <MoneyIcon size={30} color="#5bcfc5" className="icon"/>
                      ) : (
                        <MoneyIcon size={30} className="icon"/>
                      )}

                      <span
                        className={`txt ${
                          location.pathname === "/Commission" ||
                          location.pathname === "/CommissionEdit"
                            ? "txt-active"
                            : ""
                        } `}
                      >
                        Commission
                      </span>
                    </div>
                    {location.pathname === "/Commission" ||
                    location.pathname === "/CommissionEdit" ? (
                      <RightAllowIcon size={20} color="#5bcfc5" className="icon-right"/>
                    ) : (
                      <RightAllowIcon size={20} className="icon-right"/>
                    )}
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TabBar;
