import api from "./API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const upload = (formData) => {
  return api.post(
    `${ApiEndpointConstants.UPLOAD}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
