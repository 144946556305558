import React, { Component } from "react";
import "../styles/edit.scss";
import HeaderBar from "./common/HeaderBar";
import TabBar from "./common/TabBar";
import FootBar from "./common/FooterBar";
import CloseIcon from "../icons/CloseIcon";

import RemoveIcon from "../icons/RemoveIcon";

import Loading from "../components/common/Loading";
import Select from "react-select";
import cookie from "react-cookies";
import { setAPIAuthorizationToken } from "../apis/API";
import * as ModifyAPI from "../apis/ModifyAPI";
import * as UploadAPI from "../apis/UploadAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import * as SubmitAPI from "../apis/SubmitAPI";
import * as EditAPI from "../apis/EditAPI";
import * as GetCountrysAPI from "../apis/GetCountrysAPI";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      id: "",
      userID: "",
      registerArr: [
        { value: 0, label: "quickly" },
        {
          value: 1,
          label: "slowly",
        },
      ],
      stateArr: [
        { value: 0, label: "Wait Register" },
        { value: 3, label: "Already Triggered" },
        {
          value: 4,
          label: "Selife Success",
        },
        { value: 6, label: "Wrong Information" },
        { value: 7, label: "Account Die" },
      ],
      userStateArr: [
        {
          label: "Wait Register",
          value: 0,
        },
        {
          label: "Error Information",
          value: 1,
        },
        {
          label: "Wait Trigger",
          value: 2,
        },
        {
          label: "Already Triggered",
          value: 3,
        },
        {
          label: "Selife Success",
          value: 4,
        },
        {
          label: "Wrong Information",
          value: 6,
        },
        {
          label: "Account Die",
          value: 7,
        },
      ],
      title: "Modify",
      countryArr: [],
      website: "",
      email: "",
      emailPassword: "",
      emailSite: "",
      bank: "",
      note1: "",
      note2: "",
      stripePassword: "",
      FA: "",
      pk: "",
      sk: "",
      backCode: "",
      registerMethod: [],
      stateValue: [],
      newStateValue: [],
      country: [],
      img: [],
      image: "",
      faceLink: "",
      emailLink: "",
      card: "",
    };
    this.clickBack = this.clickBack.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.clickRemoveItem = this.clickRemoveItem.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    // 取cookie
    const param = cookie.load("token");
    if (param) {
      this.setState({ userID: param.group_id });
      setAPIAuthorizationToken(param.token);

      let { location } = this.props;
      let id = location.search.replace("?id=", "");
      if (id) {
        console.log(id);
        this.setState({ id });
        this.setState({ loadingShow: true });
        ModifyAPI.modify(id).then((res) => {
          this.setState({ loadingShow: false });
          console.log(res);
          var code = res.data.code;
          if (code === 1) {
            var data = res.data.data;
            console.log(data);
            if (data) {
              this.setState({ title: data.email });
              // this.setState({website:data.})
              this.setState({ bank: data.bankaccount });
              this.setState({ note1: data.remarks });
              this.setState({ note2: data.remarks1 });
              this.setState({ FA: data.stripe2fa });
              this.setState({ email: data.email });
              this.setState({ pk: data.pk });
              this.setState({ sk: data.sk });
              this.setState({ backCode: data.stripecode });
              this.setState({ website: data.websiteUrl });
              this.setState({ stripePassword: data.stripepsw });
              this.setState({ emailPassword: data.emailpsw });
              this.setState({ emailLink: data.verifyurl });
              this.setState({ faceLink: data.faceurl });
              this.setState({ emailSite: data.logemail });
              this.setState({ card: data.card1 });

              // country
              var countryArr = data.countrys;
              console.log(countryArr);
              countryArr = countryArr.map((item, index) => {
                let formCountry = {
                  label: item,
                  value: item,
                };
                return formCountry;
                //  item = formCountry;
                console.log(item);
              });
              this.setState({ countryArr: countryArr });

              var country = {
                value: data.country,
                label: data.country,
              };
              this.setState({ country });

              // state
              const { stateArr, userStateArr, userID } = this.state;
              if (userID === 3) {
                stateArr.map((item, index) => {
                  if (item.value === data.state) {
                    this.setState({ stateValue: item });
                    this.setState({ newStateValue: item });
                  }
                });
              }
              if (userID === 2) {
                userStateArr.map((item, index) => {
                  if (item.value === data.state) {
                    this.setState({ stateValue: item });
                    this.setState({ newStateValue: item });
                    this.setState({ userStateValue: item });
                  }
                });
              }
              //

              // register
              const { registerArr } = this.state;
              registerArr.map((item) => {
                if (item.value === data.pass) {
                  this.setState({ registerMethod: item });
                }
              });
              //
              if (this.state.userID === 2) {
                const { img } = this.state;
                if (data.image && data.image.length > 0) {
                  let imgArr = data.image.split(",");
                  console.log(imgArr);
                  this.setState({ img: imgArr });
                }
              }
            }
          }
        });
      } else {
        GetCountrysAPI.getCountrys().then((res) => {
          console.log(res);
          console.log(res);
          var code = res.data.code;
          if (code === 1) {
            var data = res.data.data;
            console.log(data);
            if (data) {
              // country
              var countryArr = data;
              console.log(countryArr);
              countryArr = countryArr.map((item, index) => {
                let formCountry = {
                  label: item,
                  value: item,
                };
                return formCountry;
                //  item = formCountry;
                console.log(item);
              });
              this.setState({ countryArr: countryArr });
            }
          }
        });
      }
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
  }

  clickBack() {
    window.history.back();
  }

  inputChange(type, e) {
    switch (type) {
      case "website":
        this.setState({ website: e.target.value });
        break;
      case "email":
        this.setState({ email: e.target.value });
        break;
      case "emailPassword":
        this.setState({ emailPassword: e.target.value });
        break;
      case "emailSite":
        this.setState({ emailSite: e.target.value });
        break;
      case "bank":
        this.setState({ bank: e.target.value });
        break;
      case "note1":
        this.setState({ note1: e.target.value });
        break;
      case "note2":
        this.setState({ note2: e.target.value });
        break;
      case "stripePassword":
        this.setState({ stripePassword: e.target.value });
        break;
      case "FA":
        this.setState({ FA: e.target.value });
        break;
      case "pk":
        this.setState({ pk: e.target.value });
        break;
      case "sk":
        this.setState({ sk: e.target.value });
        break;
      case "backCode":
        this.setState({ backCode: e.target.value });
        break;
      case "registerMethod":
        console.log(e.value);
        this.setState({ registerMethod: e });
        break;
      case "newStateValue":
        this.setState({ newStateValue: e });
        break;
      case "country":
        this.setState({ country: e });
        break;
      case "faceLink":
        this.setState({ faceLink: e.target.value });
        break;
      case "emailLink":
        this.setState({ emailLink: e.target.value });
        break;
      case "card":
        this.setState({ card: e.target.value });
        break;
      default:
        break;
    }
  }

  uploadFile(e) {
    console.log(e.target.files);
    const { img } = this.state;
    console.log(e.target.files);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.setState({ loadingShow: true });
    UploadAPI.upload(formData).then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var data = res.data.data;
        if (data) {
          img.push(data.url);
          this.setState({ img });
        }
      }
    });
  }

  clickRemoveItem(index) {
    const { img } = this.state;
    img.splice(index, 1);
    this.setState({ img });
  }

  submit() {
    let { location } = this.props;
    let id = location.search.replace("?id=", "");
    const { userID, img } = this.state;
    const param = cookie.load("token");
    console.log();
    if (id) {
      if (userID === 3) {
        let params = {
          token: param.token,
          "row[id]": id,
          "row[websiteUrl]": this.state.website,
          "row[email]": this.state.email,
          "row[emailpsw]": this.state.emailPassword,
          "row[logemail]": this.state.emailSite,
          "row[bankaccount]": this.state.bank,
          "row[remarks]": this.state.note1,
          "row[card1]": this.state.card,
          "row[pass]": this.state.registerMethod.value,
          "row[state]": this.state.newStateValue.value,
          "row[stripe2fa]": this.state.FA,
          "row[stripepsw]": this.state.stripePassword,
          "row[stripecode]": this.state.backCode,
          "row[remarks1]": this.state.note2,
          "row[pk]": this.state.pk,
          "row[sk]": this.state.sk,
          "row[serverip]": 1,
          "row[serveraccount]": 1,
          "row[serverpsw]": 1,
          country: this.state.country.value,
        };
        console.log(params);
        console.log(params);
        this.setState({ loadingShow: true });
        SubmitAPI.submit(params).then((res) => {
          this.setState({ loadingShow: false });
          console.log(res);
          var msg = res.data.msg;
          var code = res.data.code;
          if (code === 1) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: msg,
              })
            );
            this.clickBack();
          }
        });
      } else if (userID === 2) {
        let params = {
          token: param.token,
          id: id,
          faceurl: this.state.faceLink,
          verifyurl: this.state.emailLink,
          bankaccount: this.state.bank,
          remarks: this.state.note1,
          state: this.state.stateValue.value,
          newstate: this.state.newStateValue.value,
          stripe2fa: this.state.FA,
          stripepsw: this.state.stripePassword,
          stripecode: this.state.backCode,
          remarks1: this.state.note2,
          pk: this.state.pk,
          sk: this.state.sk,
          image: img.join(","),
          country: this.state.country.value,
        };
        console.log(params);
        this.setState({ loadingShow: true });
        EditAPI.edit(params).then((res) => {
          this.setState({ loadingShow: false });
          console.log(res);
          var msg = res.data.msg;
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: msg,
            })
          );
          this.clickBack();
        });
      }
    } else {
      if (userID === 3) {
        let params = {
          token: param.token,
          "row[websiteUrl]": this.state.website,
          "row[email]": this.state.email,
          "row[emailpsw]": this.state.emailPassword,
          "row[logemail]": this.state.emailSite,
          "row[bankaccount]": this.state.bank,
          "row[remarks]": this.state.note1,
          "row[card1]": this.state.card,
          "row[pass]": this.state.registerMethod.value,
          "row[state]": this.state.newStateValue.value,
          "row[stripe2fa]": this.state.FA,
          "row[stripepsw]": this.state.stripePassword,
          "row[stripecode]": this.state.backCode,
          "row[remarks1]": this.state.note2,
          "row[pk]": this.state.pk,
          "row[sk]": this.state.sk,
          "row[serverip]": 1,
          "row[serveraccount]": 1,
          "row[serverpsw]": 1,
          country: this.state.country.value,
        };
        console.log(params);
        console.log(params["row[pass]"]);
        console.log(params["row[state]"]);
        console.log(params.country);
        if (params["row[pass]"] === undefined) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Please Choose Register Method",
            })
          );
          return;
        } else if (params["row[state]"] === undefined) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Please Choose State",
            })
          );
          return;
        } else if (params.country === undefined) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "Please Choose Country",
            })
          );
          return;
        }
        this.setState({ loadingShow: true });
        SubmitAPI.submit(params).then((res) => {
          this.setState({ loadingShow: false });
          console.log(res);
          var msg = res.data.msg;
          var code = res.data.code;
          if (code === 1) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: msg,
              })
            );
            this.clickBack();
          }
        });
      }
    }
  }

  render() {
    const {
      countryArr,
      registerArr,
      stateArr,
      userStateArr,
      userID,
      website,
      email,
      emailPassword,
      emailSite,
      bank,
      note1,
      note2,
      stripePassword,
      FA,
      pk,
      sk,
      backCode,
      registerMethod,
      stateValue,
      userStateValue,
      country,
      title,
      img,
      faceLink,
      emailLink,
      card,
      newStateValue,
      id,
    } = this.state;
    return (
      <React.Fragment>
        <Loading loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        <div className="content-body">
          <div className="edit-contain">
            <h1>{title}</h1>
            <div className="edit-form">
              <div className="close-btn" onClick={this.clickBack}>
                <CloseIcon size={24} />
              </div>
              {userID === 2 && (
                <React.Fragment>
                  {/* face */}
                  <div className="row">
                    <div className="title">Face verification link :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={faceLink || ""}
                        className="control"
                        placeholder="Face verification link"
                        onChange={(e) => this.inputChange("faceLink", e)}
                      />
                    </div>
                  </div>
                  {/* email */}
                  <div className="row">
                    <div className="title">Email verification link :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={emailLink || ""}
                        className="control"
                        placeholder="Email verification link"
                        onChange={(e) => this.inputChange("emailLink", e)}
                      />
                    </div>
                  </div>

                  {/* stripe password */}
                  <div className="row">
                    <div className="title">Stripe Password :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={stripePassword || ""}
                        className="control"
                        placeholder="Stripe Password"
                        onChange={(e) => this.inputChange("stripePassword", e)}
                      />
                    </div>
                  </div>
                  {/* 2FA */}
                  <div className="row">
                    <div className="title">2FA :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={FA || ""}
                        className="control"
                        placeholder="2FA"
                        onChange={(e) => this.inputChange("FA", e)}
                      />
                    </div>
                  </div>
                  {/* pk */}
                  <div className="row">
                    <div className="title">PK :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={pk || ""}
                        className="control"
                        placeholder="PK"
                        onChange={(e) => this.inputChange("pk", e)}
                      />
                    </div>
                  </div>
                  {/* SK */}
                  <div className="row">
                    <div className="title">SK :</div>
                    <div className="input">
                      <input
                        type="text"
                        className="control"
                        placeholder="SK"
                        value={sk || ""}
                        onChange={(e) => this.inputChange("sk", e)}
                      />
                    </div>
                  </div>
                  {/* 紧急恢复码 */}
                  <div className="row">
                    <div className="title">Back Code :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={backCode || ""}
                        className="control"
                        placeholder="Back Code"
                        onChange={(e) => this.inputChange("backCode", e)}
                      />
                    </div>
                  </div>
                  {/* country */}
                  <div className="row">
                    <div className="title">Country :</div>
                    <div className="input">
                      <Select
                        options={countryArr}
                        className="select"
                        value={country || ""}
                        onChange={(e) => this.inputChange("country", e)}
                      />
                    </div>
                  </div>
                  {/*ID card */}
                  {/* 打款证明 */}
                  <div className="prove">
                    <div className="row">
                      <div className="title">ID Card photo :</div>
                      <div className="file-box">
                        <div className="file">
                          <p>Choose File</p>
                          <div className="btn">Browse</div>
                          <input
                            type="file"
                            className="file-input"
                            onChange={(e) => this.uploadFile(e)}
                          />
                        </div>
                        <div className="img-row">
                          {img &&
                            img.map((item, index) => {
                              return (
                                <div className="img-box" key={index || ""}>
                                  <div
                                    className="remove"
                                    onClick={() => this.clickRemoveItem(index)}
                                  >
                                    <RemoveIcon size={20} color="#000" />
                                  </div>
                                  <img src={BASE_URL + item} />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*state  */}
                  <div className="row">
                    <div className="title">State :</div>
                    <div className="input">
                      <Select
                        options={userStateArr}
                        value={newStateValue || ""}
                        onChange={(e) => this.inputChange("newStateValue", e)}
                      />
                    </div>
                  </div>
                  {/* 备注 */}
                  <div className="row">
                    <div className="title">Note :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={note1 || ""}
                        className="control"
                        placeholder="Note"
                        onChange={(e) => this.inputChange("note1", e)}
                      />
                    </div>
                  </div>
                  {/* remark */}
                  <div className="row">
                    <div className="title">Note2 :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={note2 || ""}
                        className="control"
                        placeholder="Remark"
                        onChange={(e) => this.inputChange("note2", e)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {userID === 3 && (
                <React.Fragment>
                  {/* website */}
                  <div className="row">
                    <div className="title">Website :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={website || ""}
                        className="control"
                        placeholder="Website"
                        onChange={(e) => this.inputChange("website", e)}
                      />
                    </div>
                  </div>
                  {/* email */}
                  <div className="row">
                    <div className="title">Email :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={email || ""}
                        className="control"
                        placeholder="Email"
                        onChange={(e) => this.inputChange("email", e)}
                      />
                    </div>
                  </div>
                  {/* email password*/}
                  <div className="row">
                    <div className="title">Email Password :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={emailPassword || ""}
                        className="control"
                        placeholder="Email Password"
                        onChange={(e) => this.inputChange("emailPassword", e)}
                      />
                    </div>
                  </div>
                  {/* email site */}
                  <div className="row">
                    <div className="title">Email Site :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={emailSite}
                        className="control"
                        placeholder="Email Site"
                        onChange={(e) => this.inputChange("emailSite", e)}
                      />
                    </div>
                  </div>
                  {/* bank */}
                  <div className="row">
                    <div className="title">Bank :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={bank || ""}
                        className="control"
                        placeholder="Bank"
                        onChange={(e) => this.inputChange("bank", e)}
                      />
                    </div>
                  </div>
                  {/* card1 */}
                  <div className="row">
                    <div className="title">Virtual Card :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={card || ""}
                        className="control"
                        placeholder="Virtual Card"
                        onChange={(e) => this.inputChange("card", e)}
                      />
                    </div>
                  </div>
                  {/* 备注 */}
                  <div className="row">
                    <div className="title">Note :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={note1 || ""}
                        className="control"
                        placeholder="Note"
                        onChange={(e) => this.inputChange("note1", e)}
                      />
                    </div>
                  </div>
                  {/* remark */}
                  <div className="row">
                    <div className="title">Note2 :</div>
                    <div className="input">
                      <input
                        type="text"
                        value={note2 || ""}
                        className="control"
                        placeholder="Remark"
                        onChange={(e) => this.inputChange("note2", e)}
                      />
                    </div>
                  </div>
                  {id && (
                    <React.Fragment>
                      {/* stripe password */}
                      <div className="row">
                        <div className="title">Stripe Password :</div>
                        <div className="input">
                          <input
                            type="text"
                            value={stripePassword || ""}
                            className="control"
                            placeholder="Stripe Password"
                            onChange={(e) =>
                              this.inputChange("stripePassword", e)
                            }
                          />
                        </div>
                      </div>
                      {/* 2FA */}
                      <div className="row">
                        <div className="title">2FA :</div>
                        <div className="input">
                          <input
                            type="text"
                            value={FA || ""}
                            className="control"
                            placeholder="2FA"
                            onChange={(e) => this.inputChange("FA", e)}
                          />
                        </div>
                      </div>
                      {/* pk */}
                      <div className="row">
                        <div className="title">PK :</div>
                        <div className="input">
                          <input
                            type="text"
                            value={pk || ""}
                            className="control"
                            placeholder="PK"
                            onChange={(e) => this.inputChange("pk", e)}
                          />
                        </div>
                      </div>
                      {/* SK */}
                      <div className="row">
                        <div className="title">SK :</div>
                        <div className="input">
                          <input
                            type="text"
                            className="control"
                            placeholder="SK"
                            value={sk || ""}
                            onChange={(e) => this.inputChange("sk", e)}
                          />
                        </div>
                      </div>
                      {/* 紧急恢复码 */}
                      <div className="row">
                        <div className="title">Back Code :</div>
                        <div className="input">
                          <input
                            type="text"
                            value={backCode || ""}
                            className="control"
                            placeholder="Back Code"
                            onChange={(e) => this.inputChange("backCode", e)}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {/* 注册方式 */}
                  <div className="row">
                    <div className="title">Register Method :</div>
                    <div className="input">
                      <Select
                        options={registerArr}
                        className="select"
                        value={registerMethod || ""}
                        onChange={(e) => this.inputChange("registerMethod", e)}
                      />
                    </div>
                  </div>
                  {/*state  */}
                  <div className="row">
                    <div className="title">State :</div>
                    <div className="input">
                      <Select
                        options={stateArr}
                        value={newStateValue || ""}
                        onChange={(e) => this.inputChange("newStateValue", e)}
                      />
                    </div>
                  </div>
                  {/* country */}
                  <div className="row">
                    <div className="title">Country :</div>
                    <div className="input">
                      <Select
                        options={countryArr}
                        className="select"
                        value={country || ""}
                        onChange={(e) => this.inputChange("country", e)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}

              {/* country */}

              {/* submit */}
              <div className="submit" onClick={this.submit}>
                submit
              </div>
            </div>
            <FootBar {...this.props} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Edit;
