import React from "react";
const RecodeIcon = ({  viewBox = "0 0 24 24",
size = 24,
color = "#7DBE88",
...props}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="27306"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M809.939 48c0-26.5-22.217-48-49.655-48-27.44 0-49.656 21.499-49.656 48v96c0 26.5 22.216 48 49.656 48 27.438 0 49.655-21.5 49.655-48V48zM561.656 48c0-26.5-22.25-48-49.654-48-27.408 0-49.657 21.5-49.657 48v96c0 26.5 22.25 48 49.657 48 27.406 0 49.654-21.5 49.654-48V48zM313.374 48c0-26.5-22.218-48-49.657-48-27.437 0-49.656 21.5-49.656 48v96c0 26.5 22.219 48 49.656 48 27.439 0 49.657-21.5 49.657-48V48z"
      fill={color}
      p-id="27307"
    ></path>
    <path
      d="M942.348 95.998h-82.753V144c0 53.032-44.47 96-99.311 96-54.844 0-99.313-42.97-99.313-96V96h-49.656v48c0 53.032-44.467 96-99.311 96s-99.313-42.97-99.313-96V96h-49.658v48c0 53.032-44.437 96-99.312 96-54.842 0-99.312-42.97-99.312-96V96H81.656c-27.438 0-49.657 21.5-49.657 48V976c0 26.501 22.219 48 49.657 48h860.692c27.435 0 49.652-21.499 49.652-48V144c0-26.5-22.217-48.002-49.652-48.002zM305.282 870.586c0 22.49-18.558 40.7-41.394 40.7-22.898 0-41.425-18.21-41.425-40.7v-108.49c0-22.457 18.527-40.667 41.425-40.667 22.836 0 41.394 18.21 41.394 40.668v108.489z m165.637 0c0 22.49-18.528 40.7-41.395 40.7-22.898 0-41.424-18.21-41.424-40.7V653.614c0-22.459 18.526-40.67 41.424-40.67 22.867 0 41.395 18.211 41.395 40.67v216.972z m165.637 0c0 22.49-18.496 40.7-41.394 40.7-22.867 0-41.394-18.21-41.394-40.7v-325.46c0-22.49 18.527-40.701 41.394-40.701 22.898 0 41.394 18.21 41.394 40.701v325.46z m165.668 0c0 22.49-18.526 40.7-41.424 40.7-22.838 0-41.394-18.21-41.394-40.7V436.61c0-22.458 18.556-40.67 41.394-40.67 22.897 0 41.424 18.212 41.424 40.67v433.975z"
      fill={color}
      p-id="27308"
    ></path>
  </svg>
);
export default RecodeIcon;