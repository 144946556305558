import React from "react";

const ChangeIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#9fa4a6",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="22774"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1011.250376 55.431267a40.553894 40.553894 0 0 0-29.923261-12.678013 39.372713 39.372713 0 0 0-29.923262 12.678013l-86.619968 85.990005A522.712134 522.712134 0 0 0 700.9934 37.083583a502.47456 502.47456 0 0 0-188.989021-37.01035 492.080163 492.080163 0 0 0-319.942663 111.03105A519.247335 519.247335 0 0 0 12.128419 400.651212v4.724726a20.237574 20.237574 0 0 0 6.299634 14.961631 20.473811 20.473811 0 0 0 15.040376 6.378379h132.607296a20.001338 20.001338 0 0 0 20.001338-15.749085 805.723193 805.723193 0 0 1 35.277951-77.957971A339.944002 339.944002 0 0 1 512.004379 170.320843a329.864587 329.864587 0 0 1 232.377751 91.344693l-91.344694 91.974657a40.317658 40.317658 0 0 0 0 60.004015 39.372713 39.372713 0 0 0 29.923262 12.678013h298.445162a40.868876 40.868876 0 0 0 29.923262-12.678013 40.081422 40.081422 0 0 0 12.599268-30.002008V85.039547A40.081422 40.081422 0 0 0 1011.250376 55.431267z m-25.198536 541.926018h-128.355043a19.528866 19.528866 0 0 0-19.686357 15.276613q-28.348353 66.61863-35.435441 78.036716a341.203928 341.203928 0 0 1-123.630318 119.29932 337.581639 337.581639 0 0 1-167.019047 43.309984 331.518241 331.518241 0 0 1-124.654009-23.623628 343.251309 343.251309 0 0 1-107.251269-67.957302l91.265948-91.344694a42.601275 42.601275 0 0 0-30.002007-72.603282H42.681644a43.152493 43.152493 0 0 0-42.680021 42.601275v298.681399a42.365039 42.365039 0 0 0 72.682028 29.923262l85.990004-85.990005a508.14423 508.14423 0 0 0 162.294322 104.65267 498.537288 498.537288 0 0 0 188.280312 36.695369 488.615365 488.615365 0 0 0 318.525246-110.952305 518.381136 518.381136 0 0 0 178.752116-289.625674 7.874543 7.874543 0 0 0 0.787454-4.645981 21.497501 21.497501 0 0 0-21.261265-21.654992z"
      fill={color}
      p-id="22775"
    ></path>
  </svg>
);
export default ChangeIcon;
