import React, { Component } from "react";
import "../../styles/common/footerBar.scss";

class FooterBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="footer">
            <h1 className="footer-text">Copyright www.stripevip.com 2023</h1>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
