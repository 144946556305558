import React from "react";
const MoreIcon = ({
  size = 24,
  viewBox = "0 0 24 24",
  color = "#000",
  ...props
}) => (
  <svg
    viewBox="0 0 4096 1024"
    p-id="2450"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M477.44 0C213.76 0 0 229.12 0 512s213.76 512 477.44 512S954.88 794.88 954.88 512s-213.76-512-477.44-512z m1523.2 0C1736.448 0 1522.688 229.12 1522.688 512s213.76 512 477.44 512S2478.08 794.88 2478.08 512s-213.76-512-477.44-512z m1617.92 0c-263.68 0-477.44 229.12-477.44 512s213.76 512 477.44 512S4096 794.88 4096 512s-213.76-512-477.44-512z"
      fill={color}
      p-id="2451"
    ></path>
  </svg>
);

export default MoreIcon;
