// export const BASE_URL = window.location.host.indexOf('localhost') < 0 ? (window.location.protocol + "//" + window.location.host) : 'https://test.stripevip.com';
export const BASE_URL = 'https://test.stripevip.com';
export default {
    LOGIN : '/api/user/login',
    LOGOUT : '/api/user/logOut',
    GET_CODE :'/api/user/telms',
    GET_country:'/api/user/country',
    GET_INDEX:'/api/account/index',
    GET_MONEY:'/api/user/getMoney',
    GET_ACCOUNTS:'/api/account/getAccounts',
    GET_DIVIDED:'/api/user/getDivided',
    MODIFY:'/api/account/modify',
    QUESTION:'/api/account/question',
    UPLOAD:'/api/common/upload',
    ADD:'/api/account/add',
    ADD_DIVIDED:'/api/user/addDivided',
    RECHARGE:'/api/user/recharge',
    WITHDRAW :'/api/user/withdraw',
    EDIT:'/api/account/edit',
    MATERIAL:'/api/account/material',
    COUNTRY:'api/account/getcountrys',
};
